.edd-root {
  display: block;
  position: relative;
  width: 100%;
  user-select: none;
}

.edd-root-disabled {
  color: $gray;
  cursor: not-allowed;
  opacity: 0.4;
}

.edd-head {
  overflow: hidden;
  transition: box-shadow 200ms, border-color 150ms;
  background: $white;
}

.edd-root-invalid .edd-head {
  box-shadow: 0 0 5px rgba(255, 105, 105, 0.671);
}

.edd-value {
  display: block;
  vertical-align: middle;
  padding: 10px 20px;

  @include breakpoint(large) {
    padding: 15px 20px;
  }
}

.edd-arrow {
  display: none;
}

.edd-value,
.edd-option,
.edd-group-label {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.edd-root:not(.edd-root-disabled),
.edd-option {
  cursor: pointer;
}

.styled-dropdown select {
  visibility: hidden;
}

.styled-dropdown .edd-select {
  position: absolute;
  opacity: 0;
  width: 100%;
  left: -100%;
  top: 0;
  pointer-events: none;
  visibility: visible;
}

.edd-root-native .edd-select {
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: all;
}

.edd-body {
  opacity: 0;
  position: absolute;
  left: 0;
  border: 1px solid black;
  pointer-events: none;
  overflow: hidden;
  margin: 8px 0;
  z-index: 99;
  background: white;
}

.edd-root-open .edd-body {
  opacity: 1;
  pointer-events: all;
  transform: scale(1);
  transition: opacity 200ms, transform 100ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
}

.edd-root-open-above .edd-body {
  bottom: 100%;
}

.edd-root-open-below .edd-body {
  top: 100%;
}

.edd-items-list {
  overflow: auto;
  max-height: 0;
  transition: max-height 200ms cubic-bezier(0.25, 0.46, 0.45, 0.94);
  -webkit-overflow-scrolling: touch;
}

.edd-group-label {
  font-size: 11px;
  text-transform: uppercase;
  font-weight: bold;
  letter-spacing: 0.1em;
  padding: 12px 8px 4px;
  color: #999;
}

.edd-group-has-label {
  border-bottom: 1px solid #eee;
}

.edd-option {
  @include fluid-type(32px, 70px);
  padding: 8px 12px;
  font-weight: normal;
  text-transform: uppercase;
}

.edd-group-has-label .edd-option {
  padding-left: 20px;
}

.edd-option-focused:not(.edd-option-disabled) {
  background: $black;
  color: $white;
}

.edd-option-disabled,
.edd-group-disabled .edd-option {
  cursor: default;
  color: #ccc;
}
