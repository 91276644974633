.site-header {
  z-index: 100;
}

.site-header-top {
  @include transition-fast;
  background: white;
  color: black;
  padding: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: map-get($header-height, small);

  @include breakpoint(large) {
    height: map-get($header-height, large);
  }

  .js-menu-opened & {
    background: black;
  }
}

.site-header-logo {
  font-weight: 500;
  margin: -2px 0 0;
  text-align: center;
  text-transform: uppercase;
  line-height: 1;
  font-size: 24px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @include breakpoint(md) {
    font-size: 32px;
  }

  @include breakpoint(large) {
    margin: 0;
    font-size: 38px;
  }

  a {
    display: block;
    line-height: inherit;
    color: currentColor;
    margin: auto;
    text-decoration: none;
  }

  .js-menu-opened & {
    a {
      @include transition-fast;
      color: white;
    }
  }
}

.site-menu-btn {
  @include transition-med;
  width: 28px;
  height: 19px;
  position: relative;
  transform: rotate(0deg);
  cursor: pointer;
  color: currentColor;
  background: transparent;
  border: none;
  outline: none;

  span {
    @include transition-fast;
    display: block;
    position: absolute;
    height: 3px;
    width: 100%;
    background: currentColor;
    opacity: 1;
    left: 0;
    transform: rotate(0deg);

    &:nth-child(1) {
      top: 0;
    }

    &:nth-child(2),
    &:nth-child(3) {
      top: 8px;
    }

    &:nth-child(4) {
      top: 16px;
    }
  }

  .js-menu-opened & {
    span {
      background: white;
    }

    span:nth-child(1) {
      top: 12px;
      width: 0%;
      left: 50%;
    }

    span:nth-child(2) {
      transform: rotate(45deg);
    }

    span:nth-child(3) {
      transform: rotate(-45deg);
    }

    span:nth-child(4) {
      top: 12px;
      width: 0%;
      left: 50%;
    }
  }
}

.js-menu-opened {
  overflow: hidden;
}

.site-header-nav {
  @include transition-fast;
  background: black;
  color: white;
  position: fixed;
  top: map-get($header-height, small);
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 100;
  padding: calc(var(--vertical-offset) / 2) 0 80px;
  opacity: 0;
  pointer-events: none;
  overflow-y: scroll;
  -webkit-overflow-scrolling: touch;

  @include breakpoint(large) {
    top: map-get($header-height, large);
  }

  .js-menu-opened & {
    opacity: 1;
    pointer-events: all;
  }
}

.site-header-nav-group {
  @include list-reset;
  @include fluid-font(22px, 34px);
  text-transform: uppercase;

  @include breakpoint(md-only) {
    font-size: 18px;
  }

  @include breakpoint(sm-only) {
    font-size: 16px;
  }

  li {
    margin: 5px 0;
    overflow: hidden;
  }

  a {
    color: white;
    text-decoration: none;
  }

  &--products {
    li {
      margin: 10px 0;
      line-height: 1;
    }
  }

  &--primary,
  &--site {
    a:hover {
      color: darken($gray, 30%);
    }
  }

  &--primary {
    line-height: 0.75;

    @include breakpoint(sm-only) {
      padding-bottom: 3em;
    }

    li {
      padding: 2px 0;
    }

    a {
      line-height: 9vw;
      font-size: 13vw;

      @include breakpoint(tablet) {
        line-height: 4.5vw;
        font-size: 7vw;
      }
    }

    li:last-child {
      margin-top: 3em;
    }
  }

  &--site {
    @include breakpoint(tablet) {
      padding-top: 30px;
    }

    li:not(.social-link) + .social-link {
      @include breakpoint(tablet) {
        margin-top: 30px;
      }
    }
  }
}

.section-pad {
  padding-top: var(--vertical-offset);
  padding-bottom: var(--vertical-offset);
}

.site-footer {
  @include fluid-font(22px, 34px);
  background: $black;
  color: $white;
}

.site-footer-nav-group {
  @include list-reset;
  text-transform: uppercase;

  @include breakpoint(md-only) {
    font-size: 18px;
  }

  @include breakpoint(sm-only) {
    font-size: 16px;
  }

  li {
    margin: 10px 0;
    line-height: 1;
  }

  a {
    color: $white;
    text-decoration: none;
  }

  &--primary,
  &--site {
    a:hover {
      color: darken($gray, 30%);
    }
  }

  &--primary {
    @include breakpoint(sm-only) {
      padding-bottom: 3em;
      line-height: 0.75;
    }

    a {
      @include breakpoint(sm-only) {
        font-size: 2.5em;
      }
    }

    li:last-child {
      margin-top: 30px;
    }
  }

  &--site {
    li:not(.social-link) + .social-link {
      @include breakpoint(tablet) {
        margin-top: 20px;
      }
    }
  }
}

.site-footer-callout {
  margin-top: 36px;

  h6 {
    margin: 0;
    font-size: inherit;
  }

  div {
    font-size: 0.75em;
    max-width: 250px;
  }

  a {
    color: #f6e15d;

    &:hover {
      opacity: 0.75;
    }
  }
}

.site-footer-legal {
  font-size: 16px;
  margin-top: var(--vertical-offset);
  color: $gray;

  @include breakpoint(tablet) {
    display: flex;
  }

  div {
    opacity: 0.5;
    margin-bottom: 15px;

    @include breakpoint(tablet) {
      margin: 0 25px 0 0;
    }
  }

  a {
    color: $gray;
    opacity: 0.5;
    display: inline-block;
    margin-left: 10px;

    @include breakpoint(tablet) {
      margin-left: 25px;
    }

    &:first-of-type {
      margin-left: 0;
    }

    &:hover {
      opacity: 1;
    }
  }
}

.grid-margin-x {
  margin-left: -1.25rem;
  margin-right: -1.25rem;
}

.grid-x {
  display: flex;
  flex-flow: row wrap;
}

.grid-margin-x > .cell {
  width: calc(100% - 2.5rem);
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.grid-margin-x > .small-7 {
  width: calc(58.33333% - 2.5rem);
}

.grid-margin-x > .small-5 {
  width: calc(41.66667% - 2.5rem);
}

.grid-margin-x > .tablet-12 {
  @include breakpoint(tablet) {
    width: calc(100% - 2.5rem);
  }
}

.grid-margin-x > .tablet-8 {
  @include breakpoint(tablet) {
    width: calc(66.66667% - 2.5rem);
  }
}

.grid-margin-x > .tablet-6 {
  @include breakpoint(tablet) {
    width: calc(50% - 2.5rem);
  }
}

.grid-margin-x > .tablet-4 {
  @include breakpoint(tablet) {
    width: calc(33.33333% - 2.5rem);
  }
}

.cell {
  flex: 0 0 auto;
  min-height: 0;
  min-width: 0;
  width: 100%;
}
